.page_body {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-image: url('/images/resources/bebengskitchen.jpg');
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
}

.page_main {
  width: 100%;
  height: calc(100vh - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  padding: 40px 60px;
  border-radius: 10px;
  box-shadow: 5px 5px 10px;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo_image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.soon_to_rise {
  font-size: 36px;
  font-family: 'Merienda', cursive;
  color: #d9534f;
}

.logo {
  font-size: 72px;
  font-family: 'Kaushan Script', cursive;
  color: #374151;
}

.tagline {
  font-family: 'Josefin Sans', sans-serif;
  color: #374151;
  font-size: 36px;
}

.login_container {
  padding: 20px 60px;
  border: 1px solid #374151;
  background-color: rgba(245, 245, 220, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.display {
  font-size: 40px;
  background-color: aliceblue;
  display: flex;
  justify-content: right;
  align-items: center;
  letter-spacing: 5px;
  margin: 5px;
  border: 1px solid #374151;
  height: 60px;
}

.grid_container {
  display: grid;
  grid-template-columns: auto auto auto;
  background-color: #0275d8;
  padding: 10px;
}

.login_btn_container {
  background-color: #0275d8;
  padding: 10px;
  width: 100%;
}

.grid_item {
  cursor: pointer;
  background-color: aliceblue;
  border: 1px solid #374151;
  width: 60px;
  height: 60px;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  transition: all 0.3s ease-out;
}

.grid_item:hover {
  background-color: #0275d8;
  color: aliceblue;
  border: 1px solid aliceblue;
}

.login_btn {
  font-size: 30px;
  width: 100%;
  padding: 10px;
  background-color: aliceblue;
  border: 1px solid #374151;
  transition: all 0.3s ease-out;
  cursor: pointer;
  text-align: center;
}

.login_btn_loading {
  font-size: 30px;
  width: 100%;
  padding: 10px;
  background-color: #374151;
  border: 1px solid aliceblue;
  transition: all 0.3s ease-out;
  text-align: center;
}

.login_btn:hover {
  background-color: #0275d8;
  color: aliceblue;
  border: 1px solid aliceblue;
}

@media (max-width: 768px) {
  .container {
    padding: 20px 30px;
    border: 1px solid #374151;
  }

  .logo_image {
    width: 60px;
    height: 60px;
  }

  .soon_to_rise {
    font-size: 18px;
  }

  .logo {
    font-size: 36px;
  }

  .tagline {
    font-size: 18px;
  }

  .login_container {
    margin-top: 150px;
    padding: 10px 30px;
  }

  .login_form {
    padding: 10px;
  }

  .login_label {
    font-size: 1.2rem;
  }

  .login_input {
    font-size: 1.2rem;
    padding: 5px;
  }

  .login_btn {
    font-size: 1.2rem;
    padding: 10px;
  }
}
