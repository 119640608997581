.main_top_bar {
  background-color: aliceblue;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  height: 60px;
  width: 100%;
}

.logo_container {
  display: flex;
  align-items: center;
}

.logo_image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.logo {
  position: relative;
  font-family: 'Kaushan Script', cursive;
  color: #374151;
  font-size: 36px;
  margin-right: 20px;
}

.description {
  font-size: 1.5rem;
  z-index: 2;
  margin-top: 10px;
  font-family: 'Josefin Sans', sans-serif;
}

.nav_container {
  display: flex;
}

.main_nav {
  padding: 5px 20px;
  margin-right: 10px;
  transition: all 0.3s ease-in-out;
}

.main_nav:hover {
  background-color: #374151;
  padding: 5px 20px;
  color: beige;
}

.main_nav_active {
  background-color: red;
}

@media (max-width: 1024px) {
  .logo_image {
    width: 40px;
    height: 40px;
  }

  .logo {
    font-size: 30px;
    margin-left: 10px;
  }

  .description {
    display: none;
  }
}
@media (max-width: 768px) {
  .logo_image {
    width: 40px;
    height: 40px;
  }

  .logo {
    font-size: 24px;
    margin-right: 10px;
  }

  .description {
    display: none;
  }

  .main_nav {
    padding: 10px 5px;
  }
  .main_nav:hover {
    padding: 10px 5px;
  }
}
